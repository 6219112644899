import styled from '@emotion/styled'
import { Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import type { IStyles } from '@xaero/shared'
import { Logo } from '@xaero/commons'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

interface INaviItem extends IStyles {
	[key: string]: any
}

const Container = styled.nav<INaviItem>`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	height: 60px;
	z-index: 10;
	align-items: center;
	width: 100%;
	padding: 0 28px;
	background: ${props =>
		props.isTransparent ? 'transparent' : props.theme.colors.white};
	border: ${props =>
		props.isTransparent ? 'none' : `1px solid ${props.theme.colors.gray[2]}`};
	box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
	box-shadow: ${props =>
		props.isTransparent ? 'unset' : '0 0 3px rgba(60, 72, 88, 0.15);'};
	transition: all 0.33s ease;

	.btn-onDark {
		background: 'inherit';
		border-color: ${props => props.theme.colors.border};
		color: ${props => props.theme.colors.white};
		&:hover {
			background-color: ${props => props.theme.colors.white};
			border-color: ${props => props.theme.colors.nav};
			color: ${props => props.theme.colors.text};
		}
	}

	.btn-onWhite {
		background: 'inherit';
		border-color: ${props => props.theme.colors.border};
		color: ${props => props.theme.colors.text};
		&:hover {
			border-color: ${props => props.theme.colors.nav};
			color: ${props => props.theme.colors.nav};
		}
	}
`

const NaviList = styled.div`
	display: flex;
`

const NaviItem = styled(Text)<INaviItem>`
	margin-right: ${props => props.margin || 0};
	color: ${({ theme }) => theme.colors.navText};
	font-weight: 500;
	display: flex;
	align-items: center;
	font-size: 14px;
	cursor: pointer;
	svg {
		margin-right: 6px;
	}
`

const Navi = () => {
	const { t } = useTranslation()

	const [isTransparent, setIsTransparent] = useState(true)
	const changeNavbarColor = () => {
		if (window.scrollY >= 80) {
			setIsTransparent(false)
		} else {
			setIsTransparent(true)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', changeNavbarColor)
		return () => {
			window.removeEventListener('scroll', changeNavbarColor)
		}
	}, [])

	return (
		<Container isTransparent={isTransparent}>
			<NavLink to="/">
				<Logo
					size="xl"
					color={!isTransparent ? 'blue' : 'white'}
					type="vertical"
				/>
			</NavLink>

			<NaviList>
				{/* <NavLink to="request-account">
					<NaviItem margin={'24px'}>
						<div
							className={`btn btn-pills btn-sm ${
								isTransparent ? 'btn-onDark' : 'btn-onWhite'
							}`}
						>
							{t('actions.requestAccount')}
						</div>
					</NaviItem>
				</NavLink> */}
				{/* <NavLink to="login">
					<NaviItem>
						<div
							className={`btn btn-pills btn-sm ${
								isTransparent ? 'btn-onDark' : 'btn-onWhite'
							}`}
						>
							{t('actions.login')}
						</div>
					</NaviItem>
				</NavLink> */}
			</NaviList>
		</Container>
	)
}

export default Navi
