import { UserTypeEnum } from '../gql/graphql'

export const getGraphQlURI = () => {
	if (import.meta.env.PROD) {
		return import.meta.env.REACT_APP_API_PROD
	}
	// local
	return import.meta.env.REACT_APP_API_GRAPHQL_LOCAL
}

export const getRedirectToLogin = () => {
	window.location.href = import.meta.env.PROD
		? import.meta.env.REACT_APP_LP_REDIRECT_PROD
		: import.meta.env.REACT_APP_LP_REDIRECT_DEV
}

export const getRedirectToAdmin = () => {
	window.location.href = import.meta.env.PROD
		? import.meta.env.REACT_APP_ADMIN_REDIRECT_PROD
		: import.meta.env.REACT_APP_ADMIN_REDIRECT_DEV
}

export const getRedirectToPromoter = () => {
	window.location.href = import.meta.env.PROD
		? import.meta.env.REACT_APP_PROMOTER_REDIRECT_PROD
		: import.meta.env.REACT_APP_PROMOTER_REDIRECT_DEV
}

export const getRedirectToCompany = () => {
	window.location.href = import.meta.env.PROD
		? import.meta.env.REACT_APP_COMPANY_REDIRECT_PROD
		: import.meta.env.REACT_APP_COMPANY_REDIRECT_DEV
}

export const getRedirectByUserType = (userType: UserTypeEnum) => {
	switch (userType) {
		case UserTypeEnum.Admin:
			return import.meta.env.PROD
				? import.meta.env.REACT_APP_ADMIN_REDIRECT_PROD
				: import.meta.env.REACT_APP_ADMIN_REDIRECT_DEV
		case UserTypeEnum.Company:
			return import.meta.env.PROD
				? import.meta.env.REACT_APP_COMPANY_REDIRECT_PROD
				: import.meta.env.REACT_APP_COMPANY_REDIRECT_DEV
		case UserTypeEnum.Promoter:
			return import.meta.env.PROD
				? import.meta.env.REACT_APP_PROMOTER_REDIRECT_PROD
				: import.meta.env.REACT_APP_PROMOTER_REDIRECT_DEV
		default:
			return import.meta.env.PROD
				? import.meta.env.REACT_APP_LP_REDIRECT_PROD
				: import.meta.env.REACT_APP_LP_REDIRECT_DEV
	}
}
