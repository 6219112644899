/* eslint-disable */
import { Col, Container, Row } from 'reactstrap'
import {
	IconSettingsAutomation,
	IconCurrencyEuro,
	IconTimeline,
	IconDashboard,
	IconClick,
	IconApps,
	IconCut,
	IconUserCheck,
	IconAdjustments,
} from '@tabler/icons-react'

import styled from '@emotion/styled'
import SectionTitle from '../SectionTitle/SectionTitle'

const Wrapper = styled.div`
	padding: 48px 0;
`

const iconColor = '#2f55d4'

const features = [
	{
		id: 1,
		icon: <IconSettingsAutomation size={21} color={iconColor} />,
		title: 'Request Matching',
		desc: 'Instantly match company requests with suppliers across Europe, ensuring seamless alignment between specific brand needs and supplier offerings.',
	},
	{
		id: 2,
		icon: <IconSettingsAutomation size={21} color={iconColor} />,
		title: 'Automated Procurement',
		desc: 'Automate the procurement process, reducing time and effort by intelligently streamlining supplier discovery and engagement.',
	},
	{
		id: 3,
		icon: <IconTimeline size={21} color={iconColor} />,
		title: 'Verified Supplier Network',
		desc: 'Access a meticulously vetted network of suppliers, ensuring reliability and quality in offerings within the PPE industry.',
	},
	{
		id: 4,
		icon: <IconDashboard size={21} color={iconColor} />,
		title: 'Brand-Specific Matching',
		desc: 'Ensure precision in supplier matches by aligning brand-specific requirements with supplier capabilities, fostering tailored and efficient procurement.',
	},
	{
		id: 5,
		icon: <IconClick size={21} color={iconColor} />,
		title: 'Market Access',
		desc: 'Provide a gateway for businesses to effortlessly access the European market, offering a diverse array of solutions and suppliers within the EHS landscape.',
	},
	{
		id: 6,
		icon: <IconCut size={21} color={iconColor} />,
		title: 'Efficiency Optimization',
		desc: 'Drive operational excellence by expediting the sourcing and procurement journey, allowing for swift and accurate connections between businesses and suppliers.',
	},
]

const FeaturesSection = () => (
	<Wrapper>
		<Container>
			<SectionTitle
				title="Stay Tuned!"
				hideT
				desc="The future of trade is on its way. Get ready to elevate your business game. Contact us for exclusive updates and be the first to experience the power of streamlined trade."
			/>
			{/* <Row>
				{features.map((feature, key) => (
					<Col key={key} md="6" xs="12">
						<div className="d-flex features feature-primary pt-4 pb-4">
							<div className="icon text-center rounded-circle text-primary me-3 mt-2">
								{feature.icon}
							</div>
							<div className="flex-1">
								<h4 className="title">{feature.title}</h4>
								<p className="text-muted para mb-0">{feature.desc}</p>
							</div>
						</div>
					</Col>
				))}
			</Row> */}
		</Container>
	</Wrapper>
)

export default FeaturesSection
