import { Anchor as AnchorMT, Group } from '@mantine/core'
import { IAnchorPosition } from '@xaero/shared'
import { FC } from 'react'

interface IProps {
	position?: IAnchorPosition
	label: string
	handleClick?: () => void
}

const Anchor: FC<IProps> = ({ position = 'right', handleClick, label }) => (
	<Group position={position} mt="lg">
		<AnchorMT component="button" size="sm" onClick={handleClick}>
			{label}
		</AnchorMT>
	</Group>
)

export default Anchor
