import styled from '@emotion/styled'
import type { MantineTheme } from '@mantine/core'
import { Button, Modal, Text, Title as TitleMT, Alert } from '@mantine/core'
import { IStatusTypes, IStyles } from '@xaero/shared'

interface IStyledMT extends IStyles {
	[key: string]: any
}

interface IStyledButton extends IStyles {
	[key: string]: any
	isOutline?: string
}

interface IAlert extends IStyles {
	[key: string]: any
	border?: 'hide'
}

export const getStatusBgColor = (type: IStatusTypes, theme: MantineTheme) => {
	switch (type) {
		case 'blue':
			return theme.colors.blue[1]
		case 'red':
			return theme.colors.red[0]
		case 'orange':
			return theme.colors.orange[0]
		case 'green':
			return theme.colors.green[0]
		case 'grey':
			return theme.colors.gray[0]
		default:
			return 'inherit'
	}
}

const getStatusHoverColor = (type: IStatusTypes, theme: MantineTheme) => {
	switch (type) {
		case 'blue':
			return theme.colors.blue[2]
		case 'red':
			return theme.colors.red[1]
		case 'orange':
			return theme.colors.orange[1]
		case 'green':
			return theme.colors.green[1]
		case 'grey':
			return theme.colors.gray[1]
		default:
			return 'inherit'
	}
}

export const getStatusBorderColor = (
	type: IStatusTypes,
	theme: MantineTheme,
) => {
	switch (type) {
		case 'blue':
			return theme.colors.info[0]
		case 'red':
			return theme.colors.red[7]
		case 'orange':
			return theme.colors.orange[5]
		case 'green':
			return theme.colors.green[7]
		case 'grey':
			return theme.colors.gray[4]
		default:
			return 'inherit'
	}
}

export const ButtonStyled = styled(Button)<IStyledMT>`
	background: ${props => props.theme.colors.whiteSmoked};
	box-shadow: ${({ theme }) => theme.shadows.sm};
	transition: all 0.15s;
	border: 0.5px solid ${props => props.theme.colors.border};
	color: ${props => props.theme.colors.text};
	margin-right: ${props => `${props.mr || 0}px`};
	width: ${props => `${props.width}px` || 'unset'};
	&:hover {
		transition: all 0.12s;
		border: 0.5px solid ${props => props.theme.colors.text};
		box-shadow: ${({ theme }) => theme.shadows.md};
		background: ${props => props.theme.colors.white};
	}
`

export const ButtonSubmit = styled(Button)<IStyledButton>`
	background: ${props => (props.outline ? 'inherit' : props.theme.colors.nav)};
	box-shadow: ${({ theme }) => theme.shadows.sm};
	transition: all 0.15s;
	border-radius: 8px;
	height: 42px;
	border: 0.5px solid ${props => props.theme.colors.border};
	color: ${props =>
		props.outline ? props.theme.colors.text : props.theme.colors.navText};
	&:hover {
		transition: all 0.12s;
		border: 0.5px solid ${props => props.theme.colors.text};
		box-shadow: ${({ theme }) => theme.shadows.md};
		background: ${props => props.theme.colors.white};
		color: ${props => props.theme.colors.text};
	}
`

export const ModalStyled = styled(Modal)<IStyledMT>`
	.mantine-Modal-header {
		padding: 12px 18px;
		width: 100%;
		border-bottom: 1px solid ${({ theme }) => theme.colors.body};
		color: ${({ theme }) => theme.colors.text};
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		button {
			color: ${({ theme }) => theme.colors.text};
			&:hover {
				border: 1px solid ${({ theme }) => theme.colors.nav};
			}
		}
	}
	.mantine-Modal-modal {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		padding: 0;
	}
	.mantine-Modal-body {
		padding: 18px 24px;

		@media screen and (max-width: 768px) {
			padding: 12px;
		}
	}
`

export const BuyerOfferBox = styled.div<IStyledMT>`
	background: ${props => getStatusBgColor(props.type, props.theme)};
	border: 1px solid ${props => getStatusBorderColor(props.type, props.theme)};
`

export const TitleSpacing = styled(TitleMT)<IStyledMT>`
	letter-spacing: 0.5px;
	margin-right: ${props => props.marginR || '6px'};
	white-space: nowrap;
	width: ${props => props.width || 'unset'};
`

export const TextSpacing = styled(Text)<IStyledMT>`
	letter-spacing: 0.5px;
	margin: ${props => props.marginR || '0'};
	white-space: ${props => (props.wrap ? 'wrap' : 'nowrap')};
	width: ${props => props.width || 'unset'};
`

export const AlertStyled = styled(Alert)<IAlert>`
	box-shadow: ${props =>
		props.border === 'hide' ? 'unset' : props.theme.shadows.sm};
	margin: ${props => props.margin || '8px 8px 18px 8px'};
	background-color: inherit;
	border: 1px solid
		${props =>
			props.border === 'hide' ? 'transparent' : props.theme.colors.border};
	border-radius: 6px;
	.mantine-Alert-message {
		line-height: 18px;
	}
`
