import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { IStyles } from '@xaero/shared'
import React, { PropsWithChildren } from 'react'
import { HelpDropdown } from '@xaero/commons'
import { AlertStyled } from '@xaero/shared'

interface IStyleProps extends IStyles {
	[key: string]: any
}

interface IProps {
	helper?: React.ReactNode | undefined
	children: React.ReactNode
}

const Container = styled.div<IStyleProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
`

const InputWrapper = styled.div<IStyleProps>`
	height: 100%;
	width: ${props => (props.isHelper ? 'calc(100% - 60px)' : '100%')};
`

const HelperWrapper = styled.div<IStyleProps>`
	width: 60px;
	display: flex;
	align-items: center;
	margin-top: 26px;
`

const FormHelper = ({ helper, children }: PropsWithChildren<IProps>) => {
	const { t } = useTranslation()
	return (
		<Container>
			<InputWrapper isHelper={helper}>{children}</InputWrapper>
			{helper && (
				<HelperWrapper>
					<HelpDropdown iconSize={21}>{helper}</HelpDropdown>
				</HelperWrapper>
			)}
		</Container>
	)
}

export default FormHelper
