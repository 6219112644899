import styled from '@emotion/styled'
import type { IStyles } from '@xaero/shared'
import background from '../../assets/images/bg.png'
import { Image } from '@mantine/core'

export const Container = styled.div`
	/* background-image: ${`url(${background})`}; */
`

export const Card = styled.div<IStyles>`
	width: 800px;
	padding: 40px;
	border-radius: 8px;
	position: relative;
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: ${({ theme }) => theme.shadows.sm};

	.mantine-Stepper-stepIcon {
		margin-right: 12px;
		border: 1px solid ${({ theme }) => theme.colors.border};
	}

	.mantine-Stepper-stepIcon[data-progress] {
		border-color: ${({ theme }) => theme.colors.nav};
		border-width: 2px;
	}

	.mantine-Stepper-stepBody {
		margin-top: 4px;
	}

	.mantine-Stepper-separator {
		background-color: ${({ theme }) => theme.colors.card};
	}

	.mantine-Stepper-separatorActive {
		background-color: ${({ theme }) => theme.colors.nav};
	}

	.mantine-Stepper-steps {
		margin-bottom: 32px;
	}

	.mantine-Stepper-content {
		padding-bottom: 22px;
	}
`

export const CloseWrapper = styled.div`
	display: flex;
	justify-content: center;
`

export const ErrorWrapper = styled.div`
	margin-top: 24px;
`

export const Bg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	pointer-events: none;
	overflow: hidden;
`

export const LoginWrapper = styled.div`
	z-index: 10;
	margin-top: auto !important;
	margin-bottom: auto !important;
	transition: all 0.3s ease-in-out;
	.rel {
		position: relative;
		overflow: hidden;
	}
`

export const Img = styled(Image)`
	height: 100%;
	.mantine-Image-figure,
	.mantine-Image-image,
	.mantine-Image-imageWrapper {
		height: 100%;
	}

	.mantine-Image-image {
		height: 100%;
	}
`
