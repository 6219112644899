import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import type { IStyles as IStylesMT } from '@xaero/shared'

interface IStyles extends IStylesMT {
	height: string
}

const placeholderAnimate = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: -100% 50%;
	}
	100% {
		background-position: -200% 50%;
	}
`

const Animation = styled.span`
	background: linear-gradient(to right, #f8f9fa 12%, #f1f3f5 28%, #f8f9fa 33%);
	background-size: 200%;
	width: 200px;
	animation: ${placeholderAnimate} 1.5s linear infinite;
`

export const WrapperCol = styled.span`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 8px 8px 8px;
	overflow: hidden;
`

export const Box = styled(Animation)<IStyles>`
	height: ${props => props.height || '46px'};
	width: calc(100% - 16px);
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray[2]};
	box-shadow: ${({ theme }: { theme: any }) => theme.shadows.sm};
	margin-top: 8px;
`

export const LoaderWrapper = styled.span`
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 6;
`

export const AnimatedOverlaySC = styled.span`
	background: linear-gradient(to right, #f8f9fa 12%, #f1f3f5 28%, #f8f9fa 33%);
	background-size: 200%;
	width: 100%;
	height: 100%;
	animation: ${placeholderAnimate} 1.5s linear infinite;
	opacity: 0.6;
	z-index: 5;
`

export const Overlay = styled.span`
	background: rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100%;
	z-index: 5;
`
