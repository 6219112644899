import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import { LandingPage, Login, RequestAccount } from '../pages'

const Router = () => (
	<Routes>
		<Route path="/" element={<Layout />}>
			<Route index element={<LandingPage />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Route>
		<Route path="/login" element={<Login />} />
		<Route path="/request-account" element={<RequestAccount />} />
	</Routes>
)

export default Router
