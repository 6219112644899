import { useQuery, gql } from '@apollo/client'
import { UserTypeEnum } from '../../../gql/graphql'
import { getRedirectToLogin } from '../../../utils/resolveEnv'

export const GET_ME = gql`
	{
		me {
			id
			userType
			email
			company {
				name
				enabledModules
			}
			createdAt
		}
	}
`

export const useMe = (userType: UserTypeEnum) => {
	const { client, loading, data, error } = useQuery(GET_ME, {
		onError: () => {
			getRedirectToLogin()
		},
	})

	const isLoggedIn = data?.me?.userType === userType

	const logout = () => {
		client.resetStore()
		getRedirectToLogin()
	}

	return { loading, error, logout, isLoggedIn }
}
