import { Grid, Stepper, Group } from '@mantine/core'
import { FormValidateInput } from '@mantine/form/lib/types'
import { ButtonSubmit, TitleSpacing } from '@xaero/shared'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BgContainer, Text, Textarea, Checkbox } from '@xaero/commons'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { Card } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as SC from './styles'
import { useForm } from '@mantine/form'

interface IPassReminder {
	email: string
}

interface IReqAcc {
	company: {
		name: string
		tin: string
		country: string
		city: string
	}
	user: {
		name: string
		surname: string
		email: string
		contactNumber: string
	}
	offer: {
		isSeller: boolean
		isBuyer: boolean
		sellerDescription: string
		buyerDescription: string
	}
}

const initialValues: IReqAcc = {
	company: {
		name: '',
		tin: '',
		country: '',
		city: '',
	},
	user: {
		name: '',
		surname: '',
		email: '',
		contactNumber: '',
	},
	offer: {
		isSeller: false,
		isBuyer: false,
		sellerDescription: '',
		buyerDescription: '',
	},
}

const RequestAccount = () => {
	const [active, setActive] = useState(0)
	const { t } = useTranslation()
	const form = useForm({
		initialValues,

		validate: values => {
			if (active === 0) {
				return {
					// username:
					// 	values.username.trim().length < 6
					// 		? 'Username must include at least 6 characters'
					// 		: null,
					// password:
					// 	values.password.length < 6
					// 		? 'Password must include at least 6 characters'
					// 		: null,
				}
			}

			if (active === 1) {
				return {
					// name:
					// 	values.name.trim().length < 2
					// 		? 'Name must include at least 2 characters'
					// 		: null,
					// email: /^\S+@\S+$/.test(values.email) ? null : 'Invalid email',
				}
			}

			return {}
		},
	})

	const nextStep = () =>
		setActive(current => {
			if (form.validate().hasErrors) {
				return current
			}
			return current < 3 ? current + 1 : current
		})

	const prevStep = () =>
		setActive(current => (current > 0 ? current - 1 : current))

	const validate: FormValidateInput<IReqAcc> = {}

	const handleSubmit = () => {}

	return (
		<BgContainer title={t('actions.requestAccount') as string} goBackUrl="/">
			<>
				<section className="position-relative">
					{/* <div className="bg-overlay bg-linear-gradient-2" /> */}
					<SC.Card>
						<Stepper active={active} breakpoint="sm">
							<Stepper.Step
								label={t('step.first')}
								description={t('label.companyDetails')}
							>
								<Grid>
									<Grid.Col span={12}>
										<Text
											label={t('title.companyName')}
											formProps={form.getInputProps('company.name')}
										/>
									</Grid.Col>

									<Grid.Col span={12}>
										<Text
											label={t('title.tin') as string}
											formProps={form.getInputProps('company.tin')}
										/>
									</Grid.Col>
									<Grid.Col span={6}>
										<Text
											label={t('form.country') as string}
											formProps={form.getInputProps('company.country')}
										/>
									</Grid.Col>
									<Grid.Col span={6}>
										<Text
											label={t('form.city') as string}
											formProps={form.getInputProps('company.city')}
										/>
									</Grid.Col>
								</Grid>
							</Stepper.Step>
							<Stepper.Step
								label={t('step.sec')}
								description={t('label.personalInfo')}
							>
								<Grid>
									<Grid.Col span={6}>
										<Text
											label={t('form.name') as string}
											formProps={form.getInputProps('user.name')}
										/>
									</Grid.Col>
									<Grid.Col span={6}>
										<Text
											label={t('form.surname') as string}
											formProps={form.getInputProps('user.surname')}
										/>
									</Grid.Col>

									<Grid.Col span={6}>
										<Text
											label={t('form.email') as string}
											formProps={form.getInputProps('user.email')}
										/>
									</Grid.Col>

									<Grid.Col span={6}>
										<Text
											label={t('title.contactNum') as string}
											formProps={form.getInputProps('user.contactNumber')}
										/>
									</Grid.Col>
								</Grid>
							</Stepper.Step>
							<Stepper.Step
								label={t('step.third')}
								description={t('label.yourOffer')}
							>
								<Grid>
									<Grid.Col span={12}>
										<Checkbox
											label={t('label.willYouBuy')}
											formProps={form.getInputProps('offer.isBuyer')}
										/>
									</Grid.Col>
									<Grid.Col span={12}>
										<Textarea
											label={t('label.buyDescription') as string}
											formProps={form.getInputProps('offer.buyDescription')}
											isDisabled={!form.values.offer.isBuyer}
										/>
									</Grid.Col>
									<Grid.Col span={12}>
										<Checkbox
											label={t('label.willYouSell')}
											formProps={form.getInputProps('offer.isSeller', {
												type: 'checkbox',
											})}
										/>
									</Grid.Col>
									<Grid.Col span={12}>
										<Textarea
											label={t('label.sellDescription') as string}
											formProps={form.getInputProps('offer.sellerDescription')}
											isDisabled={!form.values.offer.isSeller}
										/>
									</Grid.Col>
								</Grid>
							</Stepper.Step>
							<Stepper.Completed>Completed! Form values:</Stepper.Completed>
						</Stepper>
						<Group position="right" mt="xl">
							{active !== 0 && (
								<ButtonSubmit outline onClick={prevStep}>
									{t('actions.back')}
								</ButtonSubmit>
							)}
							{active !== 3 && (
								<ButtonSubmit outline onClick={nextStep}>
									{t('actions.nextStep')}
								</ButtonSubmit>
							)}
						</Group>
					</SC.Card>
				</section>
			</>
		</BgContainer>
	)
}

export default RequestAccount
