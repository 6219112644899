import styled from '@emotion/styled'
import { Group } from '@mantine/core'
import type { IStyles } from '@xaero/shared'

export const GroupStyled = styled(Group)<IStyles>`
	margin: 42px 0 8px 0;
`

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`
export const Helper = styled.div`
	margin-top: 20px;
`
