import styled from '@emotion/styled'
import { Loader, Select as SelectMT, Tooltip } from '@mantine/core'
import { AlertStyled, IValue, trans } from '@xaero/shared'
import React, { FC } from 'react'
import { IconLock } from '@tabler/icons-react'
import { HelpDropdown } from '@xaero/commons'
import FormHelper from '../FormHelper/FormHelper'

interface IProps {
	label: string
	placeholder?: string
	formProps: any
	isRequired?: boolean
	values?: IValue[] | undefined
	disabled?: boolean
	readOnly?: boolean
	isLoading?: boolean
	helper?: React.ReactNode
	searchable?: boolean
}

const Container = styled.div`
	display: flex;
	align-items: center;
`

const SelectWrapper = styled.div<any>`
	width: ${props => (props.isLoading ? 'calc(100% - 60px)' : '100%')};
	display: flex;

	.mantine-Select-root {
		width: 100%;
	}
`

const LoaderWrapper = styled.div`
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 33px;
	padding-top: 27px;
`

const Select: FC<IProps> = ({
	label,
	formProps,
	values = [],
	isRequired = false,
	placeholder = '',
	disabled = false,
	isLoading = false,
	searchable = undefined,
	helper = undefined,
	readOnly = false,
}) => {
	return (
		<FormHelper helper={helper}>
			<Container>
				<SelectWrapper isLoading={isLoading}>
					<SelectMT
						withAsterisk={isRequired}
						label={label}
						placeholder={placeholder}
						data={values}
						disabled={disabled || isLoading}
						readOnly={readOnly}
						searchable={searchable}
						rightSection={
							readOnly ? (
								<Tooltip label={trans('label.readOnly')}>
									<IconLock size={18} />
								</Tooltip>
							) : null
						}
						{...formProps}
					/>
				</SelectWrapper>
				{isLoading ? (
					<LoaderWrapper>
						<Loader size="sm" />
					</LoaderWrapper>
				) : null}
			</Container>
		</FormHelper>
	)
}
export default Select
