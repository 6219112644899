import styled from '@emotion/styled'
import type { IStyles } from '@xaero/shared'
import background from '../../assets/images/bg.png'
import { Image } from '@mantine/core'

export const Container = styled.div`
	/* background-image: ${`url(${background})`}; */
`

export const FormWrapper = styled.div<IStyles>`
	width: 400px;
	padding: 40px;
	position: relative;
`

export const CloseWrapper = styled.div`
	display: flex;
	justify-content: center;
`

export const ErrorWrapper = styled.div`
	margin-top: 24px;
`

export const Bg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	pointer-events: none;
	overflow: hidden;
`

export const LoginWrapper = styled.div`
	z-index: 10;
	margin-top: auto !important;
	margin-bottom: auto !important;
	transition: all 0.3s ease-in-out;
	.rel {
		position: relative;
		overflow: hidden;
	}
`

export const Img = styled(Image)`
	height: 100%;
	.mantine-Image-figure,
	.mantine-Image-image,
	.mantine-Image-imageWrapper {
		height: 100%;
	}

	.mantine-Image-image {
		height: 100%;
	}
`
