import { Outlet } from 'react-router-dom'
import React from 'react'
import Navi from '../Navi/Navi'
import * as SC from './Layout.styles'

const Layout = () => (
	<SC.Container>
		<Navi />
		<Outlet />
	</SC.Container>
)

export default Layout
