import { CommonProvider } from '@xaero/shared'
import { BrowserRouter } from 'react-router-dom'
import CoreRouter from './router'

// Import Css
import './assets/css/materialdesignicons.min.css'
import './Apps.scss'

const App = () => (
	<CommonProvider>
		<BrowserRouter>
			<CoreRouter />
		</BrowserRouter>
	</CommonProvider>
)

export default App
