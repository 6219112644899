import { graphql } from '../../gql'
import {
	BRAND_FRAGMENT,
	ORDER_ITEM_TYPE_FRAGMENT,
	DELIVERY_ADDRESS_TYPE_FRAGMENT,
	MONEY_TYPE_FRAGMENT,
} from '../fragments'

export const AUTH: any = graphql(/* GraphQL */ `
	mutation SignIn($email: String!, $password: String!) {
		signIn(email: $email, password: $password)
	}
`)

export const NEW_OFFER_REQUEST: any = graphql(/* GraphQL */ `
	mutation CreateOrder($createOrderInput: CreateOrderInput!) {
		createOrder(createOrderInput: $createOrderInput) {
			id
			createdAt
			name
			updatedAt
			status
			stage
			orderItems {
				id
				createdAt
				updatedAt
			}
			deliveryAddress {
				street
				number
				postCode
				city
				country
			}
		}
	}
`)

export const SEND_ORDER_TO_SELLERS: any = graphql(/* GraphQL */ `
	${BRAND_FRAGMENT}
	${ORDER_ITEM_TYPE_FRAGMENT}
	${DELIVERY_ADDRESS_TYPE_FRAGMENT}
	${MONEY_TYPE_FRAGMENT}

	mutation SendOrderToSellers(
		$orderUuid: Uuid!
		$sendOrderToSellersDataInput: SendOrderToSellersDataInput!
	) {
		sendOrderToSellers(
			orderUuid: $orderUuid
			sendOrderToSellersDataInput: $sendOrderToSellersDataInput
		) {
			id
			order {
				id
				createdAt
				name
				updatedAt
				status
				stage
				leadTime
				orderItems {
					...OrderItemTypeFragment
				}
				deliveryAddress {
					...DeliveryAddressTypeFragment
				}
			}
			seller {
				id
				name
				brands {
					...BrandTypeFragment
				}
				enabledModules
			}
			stage
			expiredAt
			createdAt
			updatedAt
			orderItems {
				id
				brand {
					...BrandTypeFragment
				}
				model
				quantity
				targetPrice {
					...MoneyTypeFragment
				}
				additionalDetails
				isRejected
				needType
				createdAt
				updatedAt
			}
		}
	}
`)

export const ADD_PRODUCT_VARIANT: any = graphql(/* GraphQL */ `
	${BRAND_FRAGMENT}
	${MONEY_TYPE_FRAGMENT}
	mutation AddProductVariantToSellerOffer(
		$sellerOfferId: Uuid!
		$addProductVariantInput: AddProductVariantInput!
	) {
		addProductVariantToSellerOffer(
			sellerOfferId: $sellerOfferId
			addProductVariantInput: $addProductVariantInput
		) {
			id
			brand {
				...BrandTypeFragment
			}
			model
			quantity
			price {
				...MoneyTypeFragment
			}
			additionalDetails
			availabilityDate
			createdAt
			updatedAt
		}
	}
`)

export const UPDATE_PRODUCT_VARIANT: any = graphql(/* GraphQL */ `
	${BRAND_FRAGMENT}
	${MONEY_TYPE_FRAGMENT}
	mutation UpdateProductVariant(
		$productVariantUuid: Uuid!
		$addProductVariantInput: AddProductVariantInput!
	) {
		updateProductVariant(
			productVariantUuid: $productVariantUuid
			addProductVariantInput: $addProductVariantInput
		) {
			id
			brand {
				...BrandTypeFragment
			}
			model
			quantity
			price {
				...MoneyTypeFragment
			}
			additionalDetails
			createdAt
			updatedAt
		}
	}
`)

export const DELETE_PRODUCT_VARIANT: any = graphql(/* GraphQL */ `
	mutation DeleteProductVariant($productVariantUuid: Uuid!) {
		deleteProductVariant(productVariantUuid: $productVariantUuid)
	}
`)
