import { fetch } from 'cross-fetch'
import { ApolloClient, ApolloProvider, HttpLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { PropsWithChildren } from 'react'
import { cache } from '../api/cache'
import { getGraphQlURI } from '../utils/resolveEnv'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import createMercureLink from '@xaero/shared/src/providers/MercureLink'

// eslint-disable-next-line
// const errorLink = onError(({ graphQLErrors, networkError }) => {
// 	// if (graphQLErrors) {
// 	// 	graphQLErrors.forEach(({ message, locations, path }) =>
// 	// 	console.log(
// 	// 		`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
// 	// 	),
// 	// )
// 	// }
// 	// if (networkError) {
// 	// }
// })

// const httpLink = createHttpLink({
// 	uri: getGraphQlURI(),
// 	credentials: 'include',
// })

loadDevMessages()
loadErrorMessages()

const httpLink = new HttpLink({
	uri: getGraphQlURI(),
	credentials: 'include',
	fetch,
})

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query)
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		)
	},
	createMercureLink('http://localhost/subscriptions'),
	httpLink,
)

const client = new ApolloClient({
	link: splitLink,
	cache,
})

const ApiProvider = ({ children }: PropsWithChildren) => (
	<ApolloProvider client={client}>{children}</ApolloProvider>
)
export default ApiProvider
