import { Container, Row, Col } from 'reactstrap'
import landing from '../../assets/images/about.png'
import shapes from '../../assets/images/shape1.png'
import bg from '../../assets/images/bg-circle-square.png'

const HeaderSection = () => (
	<section
		className="bg-half-170 pb-0 bg-primary d-table w-100"
		style={{ background: `url(${bg}) center center` }}
		id="home"
	>
		<Container>
			<Row className="align-items-center">
				<Col lg={7} md={6}>
					<div className="title-heading mb-md-5 pb-md-5">
						{/* <h4 className="text-white-50">Empowering Innovation</h4> */}
						<h4 className="heading text-white mb-3 title-dark">
							{' '}
							One Click, whole Europe: Hundreds of Customers and Suppliers
							Unlocked
						</h4>
						<p className="para-desc text-white-50">
							Revolutionize the trade game! Our visionary platform is in the
							works, scheduled for an early 2024 launch. Get ready as we
							transform the PPE trade landscape, connecting businesses with
							precision and lightning speed across Europe.
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	</section>
)

export default HeaderSection
