import { Button, Alert, ActionIcon } from '@mantine/core'
import { FormValidateInput } from '@mantine/form/lib/types'
import { FormGen, LoaderOverlay } from '@xaero/commons'
import type { IFormConfig, ILoginFormPayload } from '@xaero/shared'
import { InputTypes, useAuth } from '@xaero/shared'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BgContainer } from '@xaero/commons'
import { IconArrowBack } from '@tabler/icons-react'
import { Card, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as SC from './styles'

interface IPassReminder {
	email: string
}

interface ILogin {
	email: string
	password: string
	forgotPassword: null
}

const Login = () => {
	const { t } = useTranslation()
	const [isForgotPass, setIsForgotPass] = useState(false)
	const { auth, loading, errorMe, errorAuth } = useAuth()

	const handleLogin = (data: ILoginFormPayload) => {
		auth({
			variables: {
				email: data.email,
				password: data.password,
			},
		})
	}

	const handleForgotPassword = () => {
		setIsForgotPass(true)
	}

	const submitForgotPassword = () => {}
	const handleGoBack = () => {
		setIsForgotPass(false)
	}

	const initialValuesLogin: ILogin = {
		email: '',
		password: '',
		forgotPassword: null,
	}

	const fieldsLogin: IFormConfig<ILogin> = {
		children: [
			{
				size: 12,
				id: 'email',
				type: InputTypes.TEXT,
				props: {
					isRequired: true,
					label: t('data.email'),
				},
			},
			{
				id: 'password',
				size: 12,
				type: InputTypes.PASSWORD,
				props: {
					isRequired: true,
					label: t('form.password'),
				},
			},
			{
				id: 'forgotPassword',
				size: 12,
				type: InputTypes.ANCHOR,
				props: {
					isRequired: false,
					label: t('data.forgotPassword'),
					position: 'right',
					handleClick: handleForgotPassword,
				},
			},
		],
	}

	const fieldsForgot: IFormConfig<IPassReminder> = {
		children: [
			{
				size: 12,
				id: 'email',
				type: InputTypes.TEXT,
				props: {
					isRequired: true,
					label: t('data.email'),
				},
			},
		],
	}

	const validateForgot: FormValidateInput<IPassReminder> = {
		email: (value: string) => {
			if (!value.length) {
				return t('validation.required')
			}
			if (!/^\S+@\S+$/.test(value)) {
				return t('validation.invalidEmail')
			}
			return null
		},
	}

	const initialValuesForgot: IPassReminder = {
		email: '',
	}

	const validateLogin: FormValidateInput<ILogin> = {
		email: (value: string) => {
			if (!value.length) {
				return t('validation.required')
			}
			if (!/^\S+@\S+$/.test(value)) {
				return t('validation.invalidEmail')
			}
			return null
		},
		password: (value: string) => {
			if (!value.length) {
				return t('validation.required')
			}
			if (value.length < 4) {
				return t('validation.passwordLength')
			}
			return null
		},
	}

	return (
		<BgContainer title={t('actions.login') as string} goBackUrl="/">
			<>
				<section className="position-relative">
					{/* <div className="bg-overlay bg-linear-gradient-2" /> */}
					<Container fluid>
						<Row>
							<Col className="col-12 p-0">
								<div className="d-flex flex-column min-vh-100 p-4">
									<SC.LoginWrapper className="rel">
										<Card className="form-signin px-4 py-5 rounded-md shadow-sm rel">
											{loading && <LoaderOverlay />}
											{!isForgotPass ? (
												<FormGen<ILogin>
													initialValues={initialValuesLogin}
													fields={fieldsLogin}
													validate={validateLogin}
													handleSubmit={handleLogin}
													submitLabel="actions.login"
													variant="outline"
													color="dark"
													fullWidth
												/>
											) : (
												<>
													<FormGen<IPassReminder>
														initialValues={initialValuesForgot}
														fields={fieldsForgot}
														validate={validateForgot}
														handleSubmit={submitForgotPassword}
														submitLabel="actions.resetPass"
														variant="filled"
														color="blue"
														fullWidth
													/>
													<SC.CloseWrapper>
														<Button
															variant="subtle"
															color="#242830"
															onClick={handleGoBack}
														>
															{t('actions.goBack')}
														</Button>
													</SC.CloseWrapper>
												</>
											)}

											<Col xs={12} className="text-center">
												<p className="mb-0 mt-3">
													<small className="text-dark me-2">
														Don't have an account ?
													</small>
													<Link
														to="/request-account"
														className="text-dark fw-bold"
													>
														Request an account
													</Link>
												</p>
											</Col>
											{(errorAuth || errorMe) && (
												<SC.ErrorWrapper>
													<Alert title="Login error" color="red">
														{errorAuth?.message || errorMe?.message}
													</Alert>
												</SC.ErrorWrapper>
											)}
										</Card>
									</SC.LoginWrapper>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</>
		</BgContainer>
	)
}

export default Login
