import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { GlobalStyle } from '../styles/global.styles'
import { theme } from '../styles/theme'
import { FC, PropsWithChildren } from 'react'

interface IProps {
	children: JSX.Element
}

const CommonProvider: FC<PropsWithChildren<IProps>> = ({ children }) => (
	<>
		<GlobalStyle />
		<MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
			<Notifications />
			{children}
		</MantineProvider>
	</>
)

export default CommonProvider
