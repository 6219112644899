import { DatePickerInput as DateMT } from '@mantine/dates'
import { FC } from 'react'

interface IProps {
	isRequired?: boolean
	label: string
	placeholder?: string
	formProps: any
}
const DatePicker: FC<IProps> = ({
	label,
	placeholder,
	isRequired = false,
	formProps,
}) => (
		<DateMT
			withAsterisk={isRequired}
			label={label}
			placeholder={placeholder}
			defaultLevel="month"
			{...formProps}
		/>
	)

export default DatePicker
