import allblueLogo from '../assets/logo/all-blue.svg'
import blackLogo from '../assets/logo/black.svg'
import blueLogo from '../assets/logo/blue.svg'
import whiteLogo from '../assets/logo/white.svg'
import blueSygnet from '../assets/sygnet/blue.svg'
import blackSygnet from '../assets/sygnet/black.svg'
import whiteSygnet from '../assets/sygnet/white.svg'
import allblueVertical from '../assets/logo-vertical/all-blue.svg'
import blackVertical from '../assets/logo-vertical/black.svg'
import blueVertical from '../assets/logo-vertical/blue.svg'
import whiteVertical from '../assets/logo-vertical/white.svg'
import styled from '@emotion/styled'
import type { IStyles } from '@xaero/shared'

interface IProps {
	size: LogoSize
	type: LogoType
	color: LogoColor
}

type LogoSize = 'sm' | 'md' | 'lg' | 'xl'
type LogoType = 'logo' | 'sygnet' | 'vertical'
type LogoColor = 'blue' | 'black' | 'white' | 'all-blue' | 'turq' | 'grey'

interface IStylesMT extends IStyles {
	size: LogoSize
	type: LogoType
}

const getContainerSize = (size: LogoSize, type: LogoType, isWidth = false) => {
	switch (size) {
		case 'sm':
			return type === 'vertical' && isWidth ? 'auto' : '40px'
		case 'md':
			return type === 'vertical' && isWidth ? 'auto' : '60px'
		case 'lg':
			return type === 'vertical' && isWidth ? 'auto' : '80px'
		case 'xl':
			return type === 'vertical' && isWidth ? 'auto' : '115px'
	}
}

const Container = styled.div<IStylesMT>`
	width: ${({ size, type }) => getContainerSize(size, type, true)};
	height: ${({ size, type }) => getContainerSize(size, type)};
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
		color: '#E0E1E2';
	}
`

const getLogo = (type: LogoType, color: LogoColor) => {
	if (type === 'logo') {
		switch (color) {
			case 'blue':
				return blueLogo
			case 'black':
				return blackLogo
			case 'white':
				return whiteLogo
			case 'all-blue':
				return allblueLogo
		}
	}
	if (type === 'sygnet') {
		switch (color) {
			case 'blue':
				return blueSygnet
			case 'black':
				return blackSygnet
			case 'white':
				return whiteSygnet
		}
	}
	if (type === 'vertical') {
		switch (color) {
			case 'blue':
				return blueVertical
			case 'black':
				return blackVertical
			case 'white':
				return whiteVertical
			case 'all-blue':
				return allblueVertical
		}
	}
}

const Logo = ({ size, type, color }: IProps) => {
	return (
		<Container size={size} type={type}>
			<img src={getLogo(type, color)} />
		</Container>
	)
}

export default Logo
