import { useMutation, useLazyQuery } from '@apollo/client'
import { AUTH } from '../../mutations'
import { GET_ME } from './useMe'
import {
	getRedirectByUserType,
	getRedirectToLogin,
} from '../../../utils/resolveEnv'

export const useAuth = () => {
	const [getMe, { client, loading: loadingMe, error: errorMe }] = useLazyQuery(
		GET_ME,
		{
			onCompleted: data => {
				if (data?.me?.userType) {
					window.location.href = getRedirectByUserType(data.me.userType)
				}
			},
		},
	)

	const [
		auth,
		{ called, loading: loadingAuth, data: dataAuth, error: errorAuth },
	] = useMutation(AUTH, {
		onCompleted: data => {
			if (data.signIn === 'success') {
				getMe()
			}
		},
	})

	const logout = () => {
		client.resetStore()
		getRedirectToLogin()
	}

	const loading = loadingAuth || loadingMe

	return { auth, called, loading, dataAuth, errorMe, errorAuth, logout }
}
