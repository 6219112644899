import {
	Box,
	CloseButton,
	MultiSelect as MultiselectMT,
	MultiSelectValueProps,
	SelectItemProps,
} from '@mantine/core'
import { IValue } from '@xaero/shared'
import { FC, forwardRef } from 'react'
import * as SC from './Multiselect.styles'

interface IProps {
	label: string
	placeholder?: string
	formProps: any
	isRequired?: boolean
	values?: IValue[] | undefined
}

function Value({
	value,
	label,
	onRemove,
	classNames,
	...others
}: MultiSelectValueProps & { value: string }) {
	return (
		<div {...others}>
			<SC.BoxStyled>
				<Box sx={{ lineHeight: 1, fontSize: 12 }}>{label}</Box>
				<CloseButton
					onMouseDown={onRemove}
					variant="transparent"
					size={22}
					iconSize={14}
					tabIndex={-1}
				/>
			</SC.BoxStyled>
		</div>
	)
}

const Item = forwardRef<HTMLDivElement, SelectItemProps>(
	({ label, value, ...others }, ref) => (
		<div ref={ref} {...others}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<div>{label}</div>
			</Box>
		</div>
	),
)

const Multiselect: FC<IProps> = ({
	label,
	formProps,
	values,
	isRequired = false,
	placeholder = '',
}) => (
	<MultiselectMT
		z-index={11}
		withAsterisk={isRequired}
		required={isRequired}
		label={label}
		placeholder={placeholder}
		data={values}
		limit={20}
		valueComponent={Value}
		itemComponent={Item}
		dropdownPosition="flip"
		searchable
		{...formProps}
	/>
)

export default Multiselect
