import { MantineThemeOverride } from '@mantine/core'
import { size } from './variables'

export const pallete = {
	warning: '#fa9f47',
	info: '#0090e0',
	success: '#34c240',
	error: '#d64242',
	white: '#fff',
	nav: '#242830',
	navText: '#E0E1E2',
	body: '#DFEDF1',
	whiteSmoked: '#e8f2f5',
	card: '#D0E2E9',
	// card: '#a1c9d4',
	text: '#010000',
	light: '#DFECF1',
	border: '#CED4DA',
	purple: '#8b72be',
	actionPanel: '#cadee6',
	cardDarker: '#DAE9EC',
	cardLighten: '#deecef',
	metalicGrey: '#849095',
	drawer: '#cadee6',
	orangeCustom: '#FFAD69',
}

export const theme: MantineThemeOverride = {
	breakpoints: {
		xs: size.xs,
		sm: size.sm,
		md: size.md,
		lg: size.lg,
	},
	spacing: {
		sm: '',
	},
	colors: {
		warning: [pallete.warning],
		info: [pallete.info],
		success: [pallete.success],
		error: [pallete.error],
		white: [pallete.white],
		nav: [pallete.nav],
		navText: [pallete.navText],
		body: [pallete.body],
		card: [pallete.card],
		text: [pallete.text],
		light: [pallete.light],
		border: [pallete.border],
		cardDarker: [pallete.cardDarker],
		cardLighten: [pallete.cardLighten],
		purple: [pallete.purple],
		actionPanel: [pallete.actionPanel],
		metalicGrey: [pallete.metalicGrey],
		drawer: [pallete.drawer],
		orangeCustom: [pallete.orangeCustom],
		whiteSmoked: [pallete.whiteSmoked],
	},
	globalStyles: () => ({
		body: {
			'.mantine-Tabs-tabsList': {
				border: 'none',
			},
			'.mantine-Alert-root': {
				padding: '12px',
			},
			'.mantine-SegmentedControl-root': {
				border: '1px solid #242830',
				height: '36px',
			},
			'.mantine-SegmentedControl-label': {
				padding: '2px 10px',
			},
			'.mantine-SegmentedControl-labelActive': {
				background: '#242830',
				border: '1px solid #242830',
			},
			'.mantine-Input-input': {
				fontSize: '12px',
				minHeight: '36px',
				borderRadius: '6px',
				letterSpacing: '.5px',
			},
			'.mantine-MultiSelect-searchInput': {
				height: 'unset',
			},
			'.mantine-InputWrapper-label': {
				fontSize: '13px',
				paddingBottom: '4px',
				paddingLeft: '6px',
				letterSpacing: '.5px',
			},
			'.mantine-Checkbox-label': {
				fontSize: '14px',
				paddingBottom: '8px',
				paddingLeft: '6px',
				letterSpacing: '.5px',
			},
			'.mantine-InputWrapper-error': {
				marginLeft: '4px',
				fontSize: '14px',
				letterSpacing: '0.5px',
			},
			'.mantine-DatePicker-rightSection': {
				transform: 'scale(1.2)',
				marginRight: '8px',
			},
			'.mantine-DatePicker-rightSection:hover': {
				svg: {
					color: pallete.nav,
				},
			},
			'.mantine-HoverCard-dropdown': {
				borderRadius: '6px',
				padding: '12px',
				border: `1px solid ${pallete.drawer}`,
			},
			'.mantine-Popover-dropdown': {
				borderRadius: '8px',
				padding: '18px',
			},
			'.mantine-Tabs-tab:hover': {
				background: pallete.card,
				// background: pallete.nav,
			},
			'.mantine-Tabs-tab': {
				letterSpacing: '1px',
				boxShadow: theme?.shadows?.sm,
			},
			'.mantine-Tabs-tab[data-active]': {
				// background: pallete.card,
				background: pallete.nav,
				color: pallete.navText,
				borderBottomLeftRadius: '4px',
				borderTopLeftRadius: '4px',
				borderBottomRightRadius: '4px',
				borderTopRightRadius: '4px',
			},
			'.mantine-Tabs-tabLabel': {
				fontSize: '15px',
			},
			'.mantine-Chip-root': {
				height: 'auto',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '6px',
				// padding: '12px',

				'.mantine-Chip-input': {
					display: 'none',
				},
				'.mantine-Chip-label': {
					height: 'auto',
					padding: '6px 18px',
					borderRadius: '6px',
					background: 'inherit',

					'.mantine-Chip-iconWrapper': {
						display: 'none',
					},
				},
				'.mantine-Chip-label[data-checked]': {
					border: `1px solid ${pallete.nav}`,
					background: 'inherit',
					// color: pallete.navText,
					// border: 'none',
				},
			},
			'.mantine-Select-dropdown': {},
			'.mantine-Select-item': {
				padding: '4px 8px!important',
			},
			'.mantine-Select-item[data-selected]': {
				background: pallete.nav,
				color: pallete.navText,
			},
			'.mantine-Input-input:focus': {
				borderColor: pallete.nav,
			},
		},
	}),
}
