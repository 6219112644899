import { Textarea as TextareaMT } from '@mantine/core'
import React, { FC } from 'react'

interface IProps {
	isRequired?: boolean
	label: string
	placeholder?: string
	formProps: any
	maxRows?: number
	minRows?: number
	isDisabled?: boolean
	readOnly?: boolean
}
const Textarea: FC<IProps> = ({
	label,
	placeholder,
	isRequired = false,
	maxRows = 4,
	minRows = 2,
	isDisabled = false,
	readOnly = false,
	formProps,
}) => (
	<TextareaMT
		withAsterisk={isRequired}
		readOnly={readOnly}
		disabled={isDisabled}
		label={label}
		placeholder={placeholder}
		autosize
		minRows={minRows}
		maxRows={maxRows}
		{...formProps}
	/>
)

export default Textarea
