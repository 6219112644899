import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import RESOURCES from '../translations/resources'

const DEFAULT_LANGUAGE = 'en'

i18n.use(initReactI18next).init({
	...RESOURCES,
	lng: DEFAULT_LANGUAGE,
	fallbackLng: DEFAULT_LANGUAGE,
	debug: false,
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
