import { Checkbox as CheckboxMT } from '@mantine/core'
import React, { FC } from 'react'

interface IProps {
	label: string
	formProps: any
}
const Checkbox: FC<IProps> = ({ label, formProps }) => (
		<CheckboxMT
			mt="sm"
			{...formProps}
			label={label}
			checked={formProps.value}
		/>
	)

export default Checkbox
