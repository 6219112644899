import styled from '@emotion/styled'
import { HoverCard } from '@mantine/core'
import { PopoverWidth } from '@mantine/core/lib/Popover/Popover.types'
import { IconHelp, IconEye } from '@tabler/icons-react'
import type { IStyles } from '@xaero/shared'
import { PropsWithChildren } from 'react'

interface IProps {
	children: React.ReactNode
	width?: PopoverWidth
	noMargin?: boolean
	color?: 'blue' | 'black'
	iconSize?: number
	iconType?: 'help' | 'eye'
}
interface IStylesMT extends IStyles {
	[key: string]: any
}

const Helper = styled.div<IStylesMT>`
	margin-left: ${props => (props.noMargin ? 0 : '8px')};
	display: flex;
	cursor: pointer;
	color: ${props =>
		props.color === 'blue'
			? props.theme.colors.blue[5]
			: props.theme.colors.dark[6]};
`

const HelpDropdown = ({
	children,
	noMargin,
	width = 280,
	iconSize = 15,
	color = 'black',
	iconType = 'help',
}: PropsWithChildren<IProps>) => (
	<HoverCard width={width} shadow="md" zIndex={6}>
		<HoverCard.Target>
			<Helper noMargin={noMargin} color={color}>
				{iconType === 'help' && <IconHelp size={iconSize} />}
				{iconType === 'eye' && <IconEye size={iconSize} />}
			</Helper>
		</HoverCard.Target>
		<HoverCard.Dropdown>{children}</HoverCard.Dropdown>
	</HoverCard>
)

export default HelpDropdown
