import transEn from './en.json'

const RESOURCES = {
  resources: {
    en: {
      translation: transEn,
    },
  },
}

export default RESOURCES
