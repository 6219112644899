import styled from '@emotion/styled'
import { Box } from '@mantine/core'
import type { IStyles } from '@xaero/shared'

interface IStylesMT extends IStyles {
	[key: string]: any
}

export const BoxStyled = styled(Box)<IStylesMT>`
	display: flex;
	cursor: default;
	align-items: center;
	color: ${({ theme }) => theme.colors.nav};
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.7px;
	background-color: ${({ theme }) => theme.colors.orange[0]};
	border: 1px solid ${({ theme }) => theme.colors.nav};
	padding: 6px 12px;
	margin: 4px;
	border-radius: 4px;

	.mantine-ActionIcon-root {
		color: ${({ theme }) => theme.colors.nav};
	}
`
