import { TextInput, Tooltip } from '@mantine/core'
import { FC } from 'react'
import { IconLock } from '@tabler/icons-react'
import { trans } from '@xaero/shared'

interface IProps {
	isRequired?: boolean
	label: string
	placeholder?: string
	formProps: any
	disabled?: boolean
	readOnly?: boolean
}

const Text: FC<IProps> = ({
	label,
	placeholder,
	isRequired = false,
	disabled = false,
	readOnly = false,
	formProps,
}) => (
	<TextInput
		withAsterisk={isRequired}
		disabled={disabled}
		readOnly={readOnly}
		label={label}
		placeholder={placeholder}
		rightSection={
			readOnly ? (
				<Tooltip label={trans('label.readOnly')}>
					<IconLock size={18} />
				</Tooltip>
			) : null
		}
		{...formProps}
	/>
)

export default Text
