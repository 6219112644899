import { Button, Grid } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormValidateInput, UseFormReturnType } from '@mantine/form/lib/types'
import { IFormConfig, IInputConfig, InputTypes } from '@xaero/shared'
import { FormEvent, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Anchor,
	Checkbox,
	Date,
	Label,
	Multiselect,
	Number,
	Password,
	Select,
	Text,
	Textarea,
} from '../FormUI'
import FormHelper from '../FormUI/FormHelper/FormHelper'
import * as SC from './FormGen.styles'

interface Values {
	[key: string]: any
}

interface IProps<T extends Values> {
	initialValues: T
	validate: FormValidateInput<T>
	handleSubmit: (values: T, event: FormEvent<HTMLFormElement>) => void
	fields: IFormConfig<T>
	submitLabel?: string
	children?: JSX.Element
	variant?: 'outline' | 'filled'
	color?: 'dark' | 'blue'
	fullWidth?: boolean
	noSubmit?: boolean
}

function getInputProps<T extends Values>(
	input: IInputConfig<T>,
	form: UseFormReturnType<T>,
) {
	switch (input.type) {
		case InputTypes.TEXT:
			return <Text {...input.props} formProps={form.getInputProps(input.id)} />
		case InputTypes.TEXTAREA:
			return (
				<Textarea {...input.props} formProps={form.getInputProps(input.id)} />
			)
		case InputTypes.NUMBER_DECIMAL:
		case InputTypes.NUMBER:
			return (
				<Number
					{...input.props}
					isDecimal={input.type === InputTypes.NUMBER_DECIMAL}
					formProps={form.getInputProps(input.id)}
				/>
			)
		case InputTypes.LABEL:
			return <Label {...input.props} />
		case InputTypes.PASSWORD:
			return (
				<Password {...input.props} formProps={form.getInputProps(input.id)} />
			)
		case InputTypes.SELECT:
			return (
				<Select {...input.props} formProps={form.getInputProps(input.id)} />
			)
		case InputTypes.MULTISELECT:
			return (
				<Multiselect
					{...input.props}
					formProps={form.getInputProps(input.id)}
				/>
			)
		case InputTypes.DATE:
			return <Date {...input.props} formProps={form.getInputProps(input.id)} />
		case InputTypes.CHECKBOX:
			return (
				<Checkbox
					{...input.props}
					formProps={form.getInputProps(input.id, { type: 'checkbox' })}
				/>
			)
		case InputTypes.ANCHOR:
			return <Anchor {...input.props} />
		default:
			return <Text {...input.props} formProps={form.getInputProps(input.id)} />
	}
}

function FormGen<T extends Values>({
	fields,
	initialValues,
	validate,
	handleSubmit,
	submitLabel = 'actions.submit',
	children = undefined,
	variant = 'outline',
	color = 'dark',
	fullWidth = false,
	noSubmit = false,
}: PropsWithChildren<IProps<T>>) {
	const { t } = useTranslation()

	const form = useForm<T>({
		initialValues,
		validate,
	})

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Grid>
				{fields.children.map((input: any) => (
					<Grid.Col key={input.id} span={input.size}>
						{getInputProps<T>(input, form)}
					</Grid.Col>
				))}
			</Grid>
			{!noSubmit && (
				<SC.GroupStyled position="right" spacing="xl" mt="md">
					{children && children}
					<Button
						fullWidth={fullWidth}
						type="submit"
						variant={variant}
						color={color}
					>
						{t(submitLabel)}
					</Button>
				</SC.GroupStyled>
			)}
		</form>
	)
}

export default FormGen
