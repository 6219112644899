import React from 'react'
import ReactDOM from 'react-dom/client'
import { TranslationProvider, ApiProvider } from '@xaero/shared'
import App from './App'

const root: HTMLElement = document.getElementById('root')!

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<ApiProvider>
			<TranslationProvider>
				<App />
			</TranslationProvider>
		</ApiProvider>
	</React.StrictMode>,
)
