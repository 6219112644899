import { ApolloLink, Observable } from '@apollo/client';
import { print } from 'graphql/language/printer';
interface Body {
    query?: string;
    operationName?: string;
    variables?: Record<string, any>;
    extensions?: Record<string, any>;
}

const createMercureLink = (subscribeUrl: RequestInfo | URL) => {
    return new ApolloLink((operation) => {
        return new Observable(observer => {

            const { operationName, extensions, variables, query } = operation;
            const body: Body = { operationName, variables, extensions };
            body.query = print(query);

            fetch(subscribeUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(async response => {
                    if (response.ok) {
                        // Parse the initial data from the HTTP response
                        const initialData = await response.json();
                        observer.next(initialData);

                        if (initialData.extensions && initialData.extensions.subscriptions && initialData.extensions.subscriptions[operation.operationName]) {
                            // Open an SSE connection to the provided SSE URL
                            const eventSource = new EventSource(initialData.extensions.subscriptions[operation.operationName].mercure_url);

                            // Handle SSE messages
                            eventSource.onmessage = (event) => {
                                const eventData = JSON.parse(event.data);
                                observer.next(eventData);
                            };

                            eventSource.onerror = (error) => {
                                observer.error(error);
                            };

                            // Clean up the SSE connection when unsubscribed
                            return () => {
                                eventSource.close();
                            };
                        } else {
                            // If no SSE URL, complete the observable immediately
                            observer.complete();
                        }
                    } else {
                        observer.error(new Error('Failed to fetch data'));
                    }
                })
                .catch(error => {
                    observer.error(error);
                });
        });
    });

}


export default createMercureLink;
