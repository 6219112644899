/* eslint-disable */
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import SectionTitle from '../SectionTitle/SectionTitle'
import seoSVG from '../../assets/images/SEO_SVG.svg'
import bg from '../../assets/images/bg-circle-square.png'
import {
	IconAffiliate,
	IconSpy,
	IconCirclesRelation,
	IconClick,
} from '@tabler/icons-react'
const features = [
	{
		id: 1,
		icon: <IconAffiliate size={37} color="#8b72be" />,
		heading: 'Effortless Request Matching',
		desc: "Tell us what you need, and we'll handle the rest. Our system intelligently matches your brand's specific requirements with a network of suppliers ready to meet your demands. No more endless searches or uncertainty—just swift, precise matches.",
	},
	{
		id: 2,
		icon: <IconCirclesRelation size={37} color="#8b72be" />,
		heading: "Connecting You to Europe's Market",
		desc: 'With our platform, the entire European market becomes accessible at your fingertips. From London to Berlin, Madrid to Rome, gain access to a diverse array of verified suppliers across the PPE industry.',
	},
	{
		id: 3,
		icon: <IconSpy size={37} color="#8b72be" />,
		heading: 'Anonymity and Streamlined Process',
		desc: 'We understand the value of confidentiality in the procurement process. Our system ensures that buyers and sellers remain unknown to each other, with our admin facilitating seamless logistics, taking the hassle out of negotiations.',
	},

	{
		id: 4,
		icon: <IconClick size={37} color="#8b72be" />,
		heading: 'Why Choose Us?',
		desc: "We're more than just a platform; we're a game-changer. Our mission is to revolutionize the industry, offering a tool that not only automates but also accelerates the entire process. We are committed to your success, aiming to make your purchase journey smoother, faster, and more efficient. In same time increasing your sales reach to whole Europe.",
	},
]

const HowItWorks = () => (
	<section
		className="section  border-bottom bg-light"
		style={{
			background: `url(${bg}) center center`,
		}}
	>
		<Container>
			<SectionTitle
				title="What we do ?"
				desc=" we're all about simplifying and supercharging the way businesses trade and cooperate. Imagine a platform that acts as your personalized matchmaker, effortlessly connecting your company's needs with verified suppliers and potential customers across Europe. Our innovative marketplace is finely tuned for B2B enterprises. Here's what we offer:"
			/>

			<Row className="align-items-center">
				{features.map((items, key) => (
					<Col md={6} xs={12} key={items.id} className="mt-5 pt-2">
						<div className={'features feature-primary text-center'}>
							<div className="image position-relative d-inline-block">
								{items.icon}
							</div>
							<div className="content mt-4">
								<h5>{items.heading}</h5>
								<p className="text-muted mb-0">{items.desc}</p>
							</div>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	</section>
)

export default HowItWorks
