import { NumberInput } from '@mantine/core'
import FormHelper from '../FormHelper/FormHelper'
import { IconCurrencyEuro } from '@tabler/icons-react'
import { FC } from 'react'
import styled from '@emotion/styled'
import type { IStyles } from '@xaero/shared'

const Wrapper = styled.div<IStyles>`
	display: flex;
`

interface IProps {
	isRequired?: boolean
	label: string
	placeholder?: string
	formProps: any
	isDecimal: boolean
	isDisabled?: boolean
	isPrice?: boolean
	readOnly?: boolean
	helper?: React.ReactNode
}
const Number: FC<IProps> = ({
	label,
	placeholder = 'Type number',
	isDecimal,
	isRequired = false,
	isDisabled = false,
	isPrice = false,
	readOnly = false,
	helper = undefined,
	formProps,
}) => {
	return (
		<FormHelper helper={helper}>
			<NumberInput
				type="number"
				required={isRequired}
				label={label}
				disabled={isDisabled}
				placeholder={placeholder}
				readOnly={readOnly}
				precision={isDecimal ? 2 : 0}
				min={isDecimal ? 0.01 : 1}
				step={isDecimal ? 0.01 : 1}
				parser={value => value.replace(/\$\s?|(,*)/g, '')}
				max={999999999}
				icon={isPrice ? <IconCurrencyEuro size="1rem" /> : undefined}
				{...formProps}
			/>
		</FormHelper>
	)
}

export default Number
