import { css, Global } from '@emotion/react'

const style = css`
	#root {
		height: 100vh;
		width: 100vw;
	}
	body {
		margin: 0;
		padding: 0;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
`

export const GlobalStyle = () => <Global styles={style} />
