import bg_abstract from '../assets/background/bg.png'
import { Text as TextMT } from '@mantine/core'
import { PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { ActionIcon } from '@mantine/core'
import { TitleSpacing, type IStyles, pallete } from '@xaero/shared'
import Logo from '../Logo/Logo'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IconArrowBack } from '@tabler/icons-react'

interface IProps {
	title?: string
	goBackUrl?: string
	hideFooter?: boolean
}

interface IStylesMT extends IStyles {
	background: string
	[key: string]: any
}

interface IStylesText extends IStyles {
	[key: string]: any
}

const Container = styled.div<IStylesMT>`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.body};
	background-image: url(${props => props.background});
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: auto;
`

const TitleWrapper = styled.div`
	position: fixed;
	top: 40px;
	right: 48px;
`

const LogoWrapper = styled.div`
	position: fixed;
	top: 18px;
	left: 48px;
`

const GoBack = styled.div`
	position: fixed;
	bottom: 28px;
	left: 48px;
`

// const Content = styled.div`
// 	/* max-height: calc(100vh - 500px); */
// `

const Footer = styled.div`
	position: fixed;
	width: 100%;
	bottom: 28px;
	left: 500px;
	left: 0;
	display: flex;
	justify-content: center;
`

const Text = styled(TextMT)<IStylesText>`
	width: 140px;
	display: flex;
	justify-content: center;
`

const BgContainer = ({
	children,
	title = undefined,
	goBackUrl = undefined,
	hideFooter = false,
}: PropsWithChildren<IProps>) => {
	const { t } = useTranslation()
	return (
		<Container background={bg_abstract}>
			<LogoWrapper>
				<Logo type="logo" size="lg" color="black" />
			</LogoWrapper>
			{title && (
				<TitleWrapper>
					<TitleSpacing order={4} color={pallete.nav}>
						{title}
					</TitleSpacing>
				</TitleWrapper>
			)}
			{goBackUrl && (
				<GoBack>
					<Link to={goBackUrl}>
						<ActionIcon
							size={42}
							variant="outline"
							aria-label="Go back icon"
							color="dark"
						>
							<IconArrowBack color={pallete.nav} />
						</ActionIcon>
					</Link>
				</GoBack>
			)}
			{!hideFooter && (
				<Footer>
					<Text>© {new Date().getFullYear()} Tornat</Text>
				</Footer>
			)}
			<>{children}</>
		</Container>
	)
}

export default BgContainer
