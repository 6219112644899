import { PasswordInput } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { FC } from 'react'

interface IProps {
	label: string
	formProps: any
	isRequired?: boolean
}

const Password: FC<IProps> = ({ label, formProps, isRequired = false }) => {
	const [visible, { toggle }] = useDisclosure(false)
	return (
		<PasswordInput
			withAsterisk={isRequired}
			// defaultValue="secret"
			label={label}
			visible={visible}
			onVisibilityChange={toggle}
			{...formProps}
		/>
	)
}

export default Password
