interface Size {
	xs: string
	sm: string
	md: string
	lg: string
}

export const size: Size = {
	xs: '400px', // for small screen mobile
	sm: '768px', // for mobile screen
	md: '1144px', // for tablets
	lg: '1280px', // for laptops
}

export const device = {
	xs: `screen and (max-width: ${size.xs})`,
	sm: `screen and (max-width: ${size.sm})`,
	md: `screen and (max-width: ${size.md})`,
	lg: `screen and (max-width: ${size.lg})`,
}

export const deviceHook = {
	xs: `(max-width: ${size.xs})`,
	sm: `(max-width: ${size.sm})`,
	md: `(max-width: ${size.md})`,
	lg: `(max-width: ${size.lg})`,
}
