import React from 'react'

export enum InputTypes {
	TEXT = 'text',
	CHECKBOX = 'checkbox',
	PASSWORD = 'password',
	LABEL = 'label',
	SELECT = 'select',
	MULTISELECT = 'multiselect',
	NUMBER = 'number',
	NUMBER_DECIMAL = 'number_decimal',
	DATE = 'date',
	TEXTAREA = 'textarea',
	ANCHOR = 'anchor',
}

export interface IValue {
	value: string
	label: string
	[key: string]: any
}

export type IAnchorPosition = 'left' | 'right' | 'center' | 'apart'

export interface IFormProps {
	isRequired: boolean
	isDisabled?: boolean
	label: string
	values?: IValue[]
	placeholder?: string
	handleClick?: () => void
	position?: IAnchorPosition
	isPrice?: boolean
	helper?: React.ReactNode
}

export interface IInputConfig<T> {
	size: number
	id: keyof T
	type: InputTypes
	props: IFormProps
}

export interface IFormConfig<T> {
	children: IInputConfig<T>[]
}
