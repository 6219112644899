import styled from '@emotion/styled'
import { Text, Title } from '@mantine/core'
import type { IStyles } from '@xaero/shared'

interface IStylesMT extends IStyles {
	[key: string]: any
}

export const TopWrapper = styled.div`
	display: flex;
`

export const Content = styled.div<IStylesMT>`
	height: 100%;
	width: 100%;
`

export const TitleStyled = styled(Title)`
	color: ${({ theme }) => theme.colors.text};
	font-size: 40px;
	font-weight: 900;
	line-height: 1.05;
	width: 500px;
`

export const Description = styled(Text)<IStylesMT>`
	color: ${({ theme }) => theme.colors.text};
	width: 500px;
	opacity: 0.75;
	line-height: 1.05;
	margin-bottom: 28px;
`
