import { FC, PropsWithChildren } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from '../i18n'

interface IProps {
	children: JSX.Element
}

const TranslationProvider: FC<PropsWithChildren<IProps>> = ({ children }) => (
	<I18nextProvider i18n={i18n}>{children}</I18nextProvider>
)

export default TranslationProvider
