import { graphql } from '../../gql'

export const MONEY_TYPE_FRAGMENT = graphql(/* GraphQL */ `
	fragment MoneyTypeFragment on MoneyType {
		__typename
		amount
		currency
	}
`)

export const BRAND_FRAGMENT = graphql(/* GraphQL */ `
	fragment BrandTypeFragment on BrandType {
		id
		name
		industrySector
		isEnabled
	}
`)

export const QUANTITY_UNIT_TYPE = graphql(/* GraphQL */ `
	fragment QuantityUnitTypeFragment on QuantityUnitType {
		id
		name
		isEnabled
	}
`)

export const ORDER_ITEM_TYPE_FRAGMENT = graphql(/* GraphQL */ `
	${MONEY_TYPE_FRAGMENT}
	${BRAND_FRAGMENT}
	${QUANTITY_UNIT_TYPE}
	fragment OrderItemTypeFragment on OrderItemType {
		id
		brand {
			...BrandTypeFragment
		}
		quantity
		quantityUnit {
			...QuantityUnitTypeFragment
		}
		model
		targetPrice {
			...MoneyTypeFragment
		}
		additionalDetails
		isRejected
		needType
		createdAt
		updatedAt
	}
`)

export const DELIVERY_ADDRESS_TYPE_FRAGMENT = graphql(/* GraphQL */ `
	fragment DeliveryAddressTypeFragment on DeliveryAddressType {
		__typename
		id
		street
		number
		postCode
		city
		country
	}
`)

export const COMPANY_TYPE_FRAGMENT = graphql(/* GraphQL */ `
	${BRAND_FRAGMENT}
	fragment CompanyTypeFragment on CompanyType {
		__typename
		id
		name
		brands {
			...BrandTypeFragment
		}
		enabledModules
	}
`)

export const ORDER_TYPE_FRAGMENT = graphql(/* GraphQL */ `
	${ORDER_ITEM_TYPE_FRAGMENT}
	${DELIVERY_ADDRESS_TYPE_FRAGMENT}
	fragment OrderTypeFragment on OrderType {
		id
		createdAt
		name
		updatedAt
		status
		stage
		leadTime
		orderItems {
			...OrderItemTypeFragment
		}
		deliveryAddress {
			...DeliveryAddressTypeFragment
		}
	}
`)

export const SELLER_OFFER_PRODUCT_VARIANT = graphql(/* GraphQL */ `
	${BRAND_FRAGMENT}
	${MONEY_TYPE_FRAGMENT}
	${QUANTITY_UNIT_TYPE}
	fragment SellerOfferProductVariantTypeFragment on SellerOfferProductVariantType {
		id
		brand {
			...BrandTypeFragment
		}
		model
		quantity
		price {
			...MoneyTypeFragment
		}
		additionalDetails
		availabilityDate
		createdAt
		updatedAt
	}
`)

export const SELLER_OFFER_ORDER_ITEM_TYPE = graphql(/* GraphQL */ `
	${BRAND_FRAGMENT}
	${MONEY_TYPE_FRAGMENT}
	${SELLER_OFFER_PRODUCT_VARIANT}
	${QUANTITY_UNIT_TYPE}
	fragment SellerOfferOrderItemTypeFragment on SellerOfferOrderItemType {
		id
		brand {
			...BrandTypeFragment
		}
		model
		quantity
		quantityUnit {
			...QuantityUnitTypeFragment
		}
		targetPrice {
			...MoneyTypeFragment
		}
		additionalDetails
		isRejected
		needType
		variants {
			...SellerOfferProductVariantTypeFragment
		}
		createdAt
		updatedAt
	}
`)
