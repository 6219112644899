/* eslint-disable */
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as SC from './styles'
import HeaderSection from '../HeaderSection/HeaderSection'
import FeaturesSection from '../FeaturesSection/FeaturesSection'
import HowItWorks from '../HowItWorks/HowItWorks'
import About from '../About/About'
import Footer from '../Footer/Footer'
import Questions from '../Questions/Questions'

interface IProps {
	isLogin?: boolean
}

const MainPage = ({ isLogin = false }: IProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const handleLogin = () => {
		navigate('login', { replace: true })
	}

	return (
		<SC.Content isLogin={isLogin}>
			<HeaderSection />
			<HowItWorks />
			<FeaturesSection />
			{/* <About /> */}
			{/* <Questions /> */}
			<Footer />
		</SC.Content>
	)
}

export default MainPage
