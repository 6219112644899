import { Row, Col } from 'reactstrap'

interface IProps {
	isLeft?: boolean
	hideT?: boolean
	title: string
	desc?: string | null
}

const SectionTitle = ({
	title,
	desc = null,
	isLeft = false,
	hideT = false,
}: IProps) => (
	<Row className="justify-content-center">
		<Col className={isLeft ? '' : 'col-12 text-center'}>
			<div className="section-title mb-4 pb-2">
				<h4 className="title mb-4"> {title}</h4>
				{!hideT ? (
					<p
						className={
							isLeft
								? 'text-muted para-desc mb-0'
								: 'text-muted para-desc mb-0 mx-auto'
						}
					>
						At <span className="text-primary fw-bold">Tornat</span> {desc}
					</p>
				) : (
					<p
						className={
							isLeft
								? 'text-muted para-desc mb-0'
								: 'text-muted para-desc mb-0 mx-auto'
						}
					>
						{desc}
					</p>
				)}
			</div>
		</Col>
	</Row>
)

export default SectionTitle
