import { Loader } from '@mantine/core'
import { LoaderWrapper, Overlay } from './styles'

function LoaderOverlay() {
	return (
		<LoaderWrapper>
			<Overlay />
			<LoaderWrapper>
				<Loader />
			</LoaderWrapper>
		</LoaderWrapper>
	)
}

export default LoaderOverlay
